/* @font-face {
  font-family:"Bree Serif";
  src: url("../public/fonts/BreeSerif-Regular.ttf") format("truetype");
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* height */
.nav-height {
  height: 100px !important;
}

.nav-height-down {
  padding: 3px 20px !important;
  max-height: 27px !important;
}

.nav-height-down:hover {
  padding: 3px 20px !important;
  max-height: 27px !important;
}

.navbar-nav>li>a {
  line-height: 80px !important;
}

.navbar-brand-centered:hover {
  background-color: unset;
}

.navbar-brand-centered {
  border-radius: 0 !important;
  max-height: 100px !important;
  height: 100px !important;
}

.navbar-brand-centered,
.navbar-collapse {
  background-color: white !important;
}

.navbar-custom ul.nav li a {
  color: #ec9600;
  margin-top: 0 !important;
  height: 125px;
  padding-top: 37px;
}

/* Padding */
.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-150 {
  padding-top: 180px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-25 {
  padding: 10px 0 !important;
}

.pledge-pad {
  padding: 1.5em 0 6em !important;
}

.pt-50 {
  padding: 50px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.plr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pad-top-header {
  padding: 4px 10px 4px;
  background-color: whitesmoke;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-10 {
  padding-right: 10px !important;
}

/* Margin */
.donate-btn {
  margin: 0 0 0 auto !important;
  padding: 7px 12px;
  line-height: 1;
  font-size: 10px;
  font-family: 'Bree Serif', Georgia, "Times New Roman", serif;
  animation: glowing 1300ms infinite;
  border: unset !important;
  background: #d12881;
}

.m-0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: 25px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.m-side {
  margin: 0 10px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

/* Text */
.text-left {
  text-align: left;
}

/* Font Weight */
.font-wb {
  font-weight: bold;
}

.font-20 {
  font-size: 20px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12em {
  font-size: 1.2em;
}

.line-height-03 {
  line-height: 0.3 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

/* Width */
.width-50p {
  width: 50%;
}

/* Table */
.table-h6 {
  font-weight: bold;
  font-size: 16px;
}

/* Background Color */
.white-bgcolor {
  background-color: white !important;
}

.lightyellow-bgcolor {
  background-color: #F3F0CF !important;
}

.white-color {
  color: white !important;
}

.green-color {
  color: #009d57 !important;
}

.blue-color {
  color: #34327c !important;
}

.pink-color {
  color: #d12881 !important;
}

.red-color {
  color: #d12828 !important;
}

.orange-color {
  color: #ff4545 !important;
}

.yellow-color {
  color: #ec9600 !important;
}

.price-title-bg {
  background: #34327c;
}

.black-color {
  color: #2b2b2b !important;
}

.gray-color {
  color: #9b9b9b !important;
}

.icon-gray {
  color: #696767 !important;
}

.orange-bgcolor {
  background-color: #ed4949 !important;
}

.green-bgcolor {
  background-color: #009d57 !important;
}

/* Flex */
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: end;
}

.nutrition-text {
  font-size: 0.8em !important;
  font-weight: 400 !important;
}

.pricing-title {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}

.pricing {
  width: 400px;
}

.payment-mode {
  padding: 15px 10px 15px 35px;
  font-size: 1.5em;
  font-weight: 700;
}

/* The container */
.container-cust {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  margin-right: 14px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursor-p {
  cursor: pointer;
}

.footer-links {
  width: 38%;
  display: flex;
  justify-content: space-around;
}

.footer-links a {
  color: #000 !important;
}

.footer-links a:hover {
  color: #d12881 !important;
}

/* Hide the browser's default radio button */
.container-cust input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ec9600;
}

/* On mouse-over, add a grey background color */
.container-cust:hover input~.checkmark {
  background-color: white;
}

/* When the radio button is checked, add a blue background */
.container-cust input:checked~.checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-cust input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-cust .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffa200;
}

.radio-btn {
  margin: 0;
  padding: 5px;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.radio-btn li {
  /* padding: 15px 0 0 0; */
  font-size: 17px;
}

.float-btn {
  position: fixed;
  bottom: 40px;
  z-index: 100;
}

.float-btn::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid #d12881;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}


@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

@keyframes glowing {
  0% {
    background-color: #d12882ab;
    box-shadow: 0 0 5px #d12882ab;
    border-color: #d12882ab;
  }
  50% {
    background-color: #d12882cc;
    box-shadow: 0 0 20px #d12882cc;
    border-color: #d12882cc;
  }
  100% {
    background-color: #d12881;
    box-shadow: 0 0 5px #a8056f;
    border-color: #d12881;
  }
}

.circle-radius {
  border-radius: 50% !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-brand-centered {
  right: unset !important;
}

/* Overrided css */
.navbar-custom ul.nav li a:hover {
  background-color: #ffffff00 !important;
  border-bottom: 0px solid !important;
  margin-top: 0 !important;
  height: 100px !important;
  color: #d12881 !important;
  padding-top: 37px;
}

.navbar-custom ul.nav li a:hover:nth-child(1) a {
  border-color: #009d57 !important;
}

.navbar-custom ul.nav li a:hover:nth-child(2) a {
  border-color: #34327c !important;
}

.navbar-custom ul.nav li a:hover:nth-child(3) a {
  border-color: #d12881 !important;
}

.navbar-custom ul.nav li a:hover:nth-child(3) a {
  border-color: #ff4545 !important;
}

.navbar-custom ul.nav li a:hover:nth-child(5) a {
  border-color: #ec9600 !important;
}

.navbar-custom ul.nav li a:hover:nth-child(6) a {
  border-color: #34327c !important;
}

.navbar-custom ul.nav li a:hover:nth-child(7) a {
  border-color: #d12881 !important;
}

.navbar-custom ul.nav li a:hover:nth-child(8) a {
  border-color: #d12828 !important;
}

.navbar-brand-centered img {
  max-height: 90px !important;
  /* top: 16% !important; */
  width: 280px !important;
  padding: 0px 30px;
}

.navbar-brand-centered {
  width: 300px !important;
  top: 24% !important;
}

#layerslider {
  padding-top: 125px;
}

.nav>.active>a {
  background-color: white !important;
  border-bottom: 2px solid !important;
  /* padding: 10px 10px !important; */
  /* padding-top: 38px; */
}

.nav>.active>a:nth-child(1) a {
  border-color: #009d57 !important;
}

.nav>.active>a:nth-child(2) a {
  border-color: #34327c !important;
}

.nav>.active>a:nth-child(3) a {
  border-color: #d12881 !important;
}

.nav>.active>a:nth-child(3) a {
  border-color: #ff4545 !important;
}

.nav>.active>a:nth-child(5) a {
  border-color: #ec9600 !important;
}

.nav>.active>a:nth-child(6) a {
  border-color: #34327c !important;
}

.nav>.active>a:nth-child(7) a {
  border-color: #d12881 !important;
}

.nav>.active>a:nth-child(8) a {
  border-color: #d12828 !important;
}

#gallery .nav>.active>a {
  background-color: #d12881 !important;
}

.subnav:hover {
  background-color: #ffa200 !important;
}

.blue-popup {
  border-radius: 225px 45px 325px 75px/15px 125px 35px 155px;
  /* font-size: 16px; */
  box-shadow: 6px 6px 0px rgb(103 102 102 / 20%);
  background: #d9edf7;
  color: #31708f;
  padding: 5px 20px;
}

.green-popup {
  border-radius: 225px 45px 325px 75px/15px 125px 35px 155px;
  /* font-size: 16px; */
  box-shadow: 6px 6px 0px rgb(103 102 102 / 20%);
  background: #dff0d8;
  color: #3c763d;
  padding: 5px 20px;
}

.yellow-popup {
  border-radius: 225px 45px 325px 75px/15px 125px 35px 155px;
  /* font-size: 16px; */
  box-shadow: 6px 6px 0px rgb(103 102 102 / 20%);
  background: #fcf8e3;
  color: #8a6d3b;
  padding: 5px 20px;
}

.border-bottom-footer {
  border-bottom: 1px solid #dadada;
  margin-bottom: 15px;
  margin-left: -25px;
  margin-right: -25px;
}

.brown-popup {
  border-radius: 225px 45px 325px 75px/15px 125px 35px 155px;
  /* font-size: 16px; */
  box-shadow: 6px 6px 0px rgb(103 102 102 / 20%);
  background: #f2dede;
  color: #a94442;
  padding: 5px 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.whatsapp-style {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  #navbar-brand-centered .container {
    width: fit-content !important;
    float: right !important;
  }
}

@media (max-width: 1024px) {
  .mt-x-50 {
    margin-top: 50px;
  }
}

.white-gray-bgcolor {
  background-color: #fbfbfb !important;
}

.width-fit {
  width: fit-content !important;
  left: -8px !important;
  min-width: unset !important;
}

.width-prog-fit {
  width: fit-content !important;
  /* left: 0px !important; */
  min-width: unset !important;
}

.flex-direction-column {
  flex-direction: column;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.w-40 {
  width: 40%;
}

.top_nav {
  height: 100px !important;
  margin-top: 0 !important;
}

.text-transform-i {
  text-transform: inherit;
}

label {
  width: 100%;
  text-align: left;
  margin: 20px 0 0;
}

.mt-40 {
  margin-top: 40px;
}

#lightbox {
  height: auto !important;
}

#lightbox_video {
  height: auto !important;
}

#lightbox_video .gallery-thumb {
  border-radius: 5%;
}

#lightbox_video .gallery-thumb .overlay-mask {
  border-radius: 5%;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.errorMsg {
  color: #cc0000;
  margin-top: 15px;
  text-align: left;
}

.sucessMsg {
  color: #6B8E23;
  margin-top: 15px;
}

.form-control {
  box-shadow: 6px 6px 0px rgb(225 224 224) !important;
}

.form-border {
  border: 1px solid;
  border-radius: 10px;
}

.pad-vh {
  padding: 10px 40px;
}

.gallery-head {
  font-size: 24px;
  font-family: 'Bree Serif', Georgia, "Times New Roman", serif !important;
  text-transform: capitalize;
  color: #fff;
  padding: 30 px;
  line-height: 8px;
  border-radius: 0;
  display: inline-block;
  font-weight: 500;
  transform-origin: left center;
  background-color: #d12881;
}

.font-12 {
  font-size: 12px;
}

.home-approach-icons-item {
  background-color: #fff;
  text-align: center;
  transition: all .3s;
  -webkit-transition: all .3s;
  overflow: hidden;
  transform: translateY(0);
  float: left;
  width: calc(40% - 4.5rem - 2px);
  border-radius: 0.5rem;
  border: 1px solid #efefef;
  height: 20vw;
  border-bottom: 0.4rem solid #efefef;
  margin: 0 0.75rem;
  padding: 2.5rem 1.5rem;
  border-bottom: 0.4rem solid #e5276a;
  box-shadow: 0 0.4rem 1rem -0.01rem rgb(0 0 0 / 10%);
}

.home-approach-icons-item h3 {
  position: relative;
  transition: all .3s;
  -webkit-transition: all .3s;
  font-size: 2rem !important;
  /* font-weight: 400; */
  color: #2f2f2f;
  opacity: 1;
  transform: translateY(0);
  padding-bottom: 1rem;
  margin: 0.5rem 0 1rem;
}

.home-approach-icons-item img {
  transition: all .3s;
  -webkit-transition: all .3s;
  display: block;
  position: relative;
  width: 100%;
  opacity: 0;
  transform: translateY(0);
  margin: 0 auto 3rem;
}

.home-approach-icons-item h3::before {
  transition: all .3s;
  -webkit-transition: all .3s;
  background-color: #fdd831;
  position: absolute;
  left: 0;
  right: 0;
  width: 10%;
  height: 2px;
  bottom: 0;
  margin: auto;
  content: '';
}

.home-approach-hidden-info {
  transition: all .3s;
  -webkit-transition: all .3s;
  opacity: 0;
  transform: translateY(1.5 rem);
}

.home-approach-icons-item:hover>.rel-wrapper {
  opacity: 0;
  display: flex;
  margin-top: 24px;
}

.home-approach-icons-item:hover {
  transform: translateY(-0.2rem);
  box-shadow: #dcdcdc 0 2px 2px, #dcdcdc 0 4px 4px, #dcdcdc 0 8px 8px, #dcdcdc 0 16px 16px, #dcdcdc 0 32px 32px !important;
}

.home-approach-icons-item:hover .home-approach-hidden-info,
.home-approach-icons-item:hover h3 {
  transform: translateY(calc(-12vw - 1rem));
  opacity: 1;
}

.home-approach-icons-item:nth-child(1):hover h3::before {
  background-color: #e5276a;
}

.home-approach-icons-item:nth-child(2):hover h3::before {
  background-color: #e5276a;
}


.home-approach-icons-item:hover h3::before {
  width: 20%;
}

.light-gray {
  background-color: whitesmoke !important;
}

.career-img {
  width: 1155px;
  height: 400px;
}

.career-btn {
  position: absolute;
  bottom: 25px;
  right: 15px;
}

.career-eng-btn {
  position: absolute;
  bottom: 25px;
  right: 200px;
}

.team-img {
  border-color: #34327C !important;
  border-radius: 50%;
  border: 10px solid;
  position: relative;
  top: 70px;
}

.MuiPopover-paper {
  overflow-y: hidden !important;
  border-radius: 22px !important;
}

.ps-30 {
  padding: 0 30px;
}

.video-border {
  width: 100% !important;
  margin-bottom: -4px !important;
}

.footer-p {
  padding: 25px;
  border-top: 10px solid #34327C;
}

.mt-100 {
  margin-top: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.mr-1 {
  margin-right: 4px;
}
.boy-icon {
  position: absolute;
  left: 0%;
  top: -5%;
}

.girl-icon {
  position: absolute;
  right: 0%;
  top: -5%;
}

.position-absolute {
  position: absolute;
  left: 15%;
  top: 15%;
}

.events-bar li span {
  background-color: unset !important;
  border: none !important;
}

.events-bar li span::before {
  color: #EC9600;
  content: "\f11a";
  font-family: 'Flaticon';
  font-weight: normal;
  position: absolute;
  top: -0.6em;
  left: 0;
  font-size: 30px;
}

.events-bar li {
  min-width: 100px;
  position: relative !important;
  display: flex;
  flex-direction: column;
}

.events-bar li:nth-child(1) {
  bottom: 50px !important;
  left: 15px !important;
}

.events-bar li:nth-child(2) {
  bottom: 30px !important;
  left: 162.5px !important;
}

.events-bar li:nth-child(3) {
  bottom: 125px !important;
  left: 367.5px !important;
}

.events-bar li:nth-child(4) {
  bottom: 102px !important;
  left: 483.5px !important;
}

.counter-text {
  font-size: 18px;
  color: #34327C;
  font-weight: 700;
}

.story-detail {
  border-color: #34327C;
  border-radius: 50%;
  border: 6px solid;
  padding: 20px;
  /* min-height: 200px; */
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.zig-zag-border {
  border-radius: 355px 45px 225px 75px/15px 225px 15px 255px;
}

.buttons li {
  display: none;
}

.flex-center {
  display: flex;
  justify-content: center;
}

h1,
a,
.quote-test:before,
.quote-test i,
.quote-test small,
#blog-page h6,
#blog-page blockquote:before,
#contact i,
.latest-caption h4 {
  color: #34327C;
}

#blog-page blockquote:before {
  content: "\f110";
  font-family: 'Flaticon';
  font-size: 2.3em;
  line-height: 0.1em;
  margin-right: 0.5em;
  vertical-align: 0.2em;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  height: 75vh;
  justify-content: center;
}

.loader-css {
  position: absolute;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.problem-section {
  padding: 30px 20px;
  margin: 0 0 20px;
  border-left: 0;
  font-weight: 700;
  border-radius: 20px;
  position: relative;
  height: 125px;
}

.problem-section div span {
  font-size: 1.3em;
  align-self: center;
}

.problem-section::before {
  background-image: url(./img/kid-new.jpg);
  border-radius: 20px;
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.problem-section_1 {
  padding: 30px 20px;
  margin: 0 0 20px;
  border-left: 0;
  font-weight: 700;
  border-radius: 20px;
  position: relative;
  height: 125px;
}

.problem-section_1 div span {
  font-size: 1.3em;
  align-self: center;
}

.problem-section_1::before {
  background-image: url(./img/kids_bg.jpg);
  border-radius: 20px;
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.h2-heading {
  position: relative;
  background-color: #ec9600;
  font-size: 30px;
  font-family: 'Bree Serif', Georgia, "Times New Roman", serif !important;
  text-transform: uppercase;
  color: #fff;
  padding: 15px;
  line-height: 50px;
  border-radius: 50%;
  display: inline-block;
  font-weight: 500;
  transform-origin: left center;
  margin: 0 15px;
}

.blur-bg {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  height: inherit;
}

.font-arrow {
  font-size: 50px;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.border-css {
  border-radius: 50%;
  min-height: 233px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff94;
}

.counter-home {
  color: #EC9600;
  font-size: 46px;
  font-weight: 700;
}

#blog-page .home_page blockquote:before {
  font-size: 1.5em !important;
}

.term-para {
  font-size: 24px;
  font-weight: 600;
  color: #d12881;
}

.blur-black-bgcolor {
  background-color: rgb(0 0 0 / 0%);
}

#partner #owl-team .owl-prev,
#partner #owl-team .owl-next,
#partner #owl-team .owl-dots {
  display: none !important;
}

#call-to-action .story-detail {
  border-color: #555555 !important;
}

.pricing-palden .pricing-deco {
  background-color: #d12881 !important;
}

.relative-22 {
  position: relative;
  top: -22px;
}

.donate-txt {
  font-size: 14px;
  color: #ec9600;
  font-weight: 700;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: transparent !important;
}

.font-2em {
  font-size: 2rem;
}

.approach-btn {
  background-color: #ec9600;
  border-color: #ec9600;
}

.approach-btn:hover {
  background-color: #ff4545;
  border-color: #ff4545;
}

.slider {
  margin-top: 20px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 95%;
    -webkit-appearance: none;
    background-color: #d12881;
    height: 10px;
    margin-top: 25px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #ec9600;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #34327c;
    box-shadow: -300px 0 0 300px #ec9600;
  }

}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #ec9600;
}

input[type="range"]::-moz-range-track {
  background-color: #34327c;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #ec9600;
}

input[type="range"]::-ms-fill-upper {
  background-color: #34327c;
}

.slider-no {
  width: 5%;
  font-size: 16px;
}

.border-pink {
  border-color: #d12881 !important;
}

.border-yellow {
  border-color: #c75900 !important;
}

.border-blue {
  border-color: #d12881 !important;
}

.border-brown {
  border-color: #d12881 !important;
}


/* .check-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ec9600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.check-group label::after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ec9600;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */



/* checkbox */


.chk {
  display: block;
  margin-bottom: 15px;
}

.chk input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.chk label {
  position: relative;
  cursor: pointer;
}

.chk label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ec9600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.chk input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ec9600;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* scroll */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f112;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.326);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.486);
}

.font-17 {
  font-size: 17.5px;
}

.w-100 {
  width: 100%;
}

.vision-img {
  opacity: 1 !important;
  width: 50% !important;
  margin: 18px auto 46px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.map-size {
  max-width: 450px;
  height: 250px;
}

.home_page_quote {
  padding: 26px 10px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 0;
  font-weight: 700;
  border-radius: 20px;
  background: #F3F0CF;
}

.m-auto {
  margin: auto;
}

#owl-testimonials .item {
  padding: 30px !important;
}

@media (max-width: 375px) {
  .navbar-brand-centered {
    width: 240px !important;
  }

  .navbar-brand-centered img {
    width: 240px !important;
  }

  footer img {
    max-width: 230px;
    margin: 35px 0px;
    width: 100%;
  }
  
  .problem-section div span {
    font-size: 0.8em;
  }

  .problem-section_1 div span {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 425px) {
  .home-approach-icons-item {
    width: calc(100% - 4.5rem - 2px) !important;
    height: 60vw !important;
  }

  .home-approach-icons-item:hover .home-approach-hidden-info,
  .home-approach-icons-item:hover h3 {
    transform: translateY(calc(-40vw - 3rem)) !important;
  }

  .vertical-center {
    height: 70vh !important;
  }

  .justify-sm-center {
    justify-content: center !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .footer-links {
    width: 100%;
  }

  .gallery-head {
    line-height: 26px;
  }

  .mt-xs-40 {
    margin-top: 40px !important;
  }
  .col-xs-10 {
    width: 80% !important;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .home-approach-icons-item {
    width: calc(100% - 4.5rem - 2px) !important;
    height: 60vw !important;
  }

  .home-approach-icons-item:hover .home-approach-hidden-info,
  .home-approach-icons-item:hover h3 {
    transform: translateY(calc(-25vw - 1rem)) !important;
  }

  .vertical-center {
    height: 50vh !important;
  }

  .justify-sm-center {
    justify-content: center !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .footer-links {
    width: 100%;
  }

  .problem-section div span {
    font-size: 1em;
  }

  .problem-section_1 div span {
    font-size: 1em;
  }
  .road-map {
    width: 400px !important;
    height: auto!important;
  }

}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .home-approach-icons-item {
    width: calc(50% - 4.5rem - 2px) !important;
    height: 50vw !important;
  }

  .home-approach-icons-item:hover .home-approach-hidden-info,
  .home-approach-icons-item:hover h3 {
    transform: translateY(calc(-25vw - 1rem)) !important;
  }

}

@media (max-width: 768px) {
  .navbar-header {
    background-color: white !important;
  }

  .header-text {
    display: none;
  }

  .col-sm-12 {
    width: 100% !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .border-css {
    max-width: 250px;
    margin: auto;
  }

  .section-heading h2 {
    line-height: 32px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .col-sm-6 {
    width: 50%;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .home-approach-icons-item {
    width: calc(50% - 4.5rem - 2px) !important;
    height: 32vw !important;
  }

  .home-approach-icons-item:hover .home-approach-hidden-info,
  .home-approach-icons-item:hover h3 {
    transform: translateY(calc(-15vw - 3rem)) !important;
  }

  .vertical-center {
    height: 30vh !important;
  }

  .col-md-6 {
    width: 50% !important;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-4 {
    width: 33.33%;
  }
  .problem-section div span {
    font-size: 1em;
  }

  .problem-section_1 div span {
    font-size: 1em;
  }
}

@media screen and (max-width: 991px) {
  .navbar-header {
    background-color: white !important;
  }

  .navbar-custom ul.nav li a {
    padding-top: 20px;
  }

  .navbar-nav>li>a {
    line-height: 30px !important;
  }

  .top_nav {
    height: 60px !important;
  }

  #navbar-brand-centered .container {
    justify-content: center;
  }

  .navbar-custom ul.nav li a:hover {
    height: 60px !important;
    padding-top: 20px;
  }

  .section-heading {
    margin-bottom: 0 !important;
  }

  .navbar-header {
    background-color: white !important;
  }


  .vision-img {
    margin: 3px auto 46px !important;
    opacity: 1 !important;
    width: 50% !important;
  }

  .pt-150 {
    padding-top: 130px !important;
  }

  .col-sm-cust-7 {
    width: fit-content !important;
  }

  .mb-sm-50 {
    margin: 0 auto 50px;
  }

  .map-size {
    width: 100% !important;
    max-width: 700px;
    height: 250px;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {

  .border-css {
    min-height: 250px !important;
  }

  .navbar-brand-centered {
    top: 24% !important;
  }

  .home-approach-icons-item {
    width: calc(50% - 4.5rem - 2px) !important;
    height: 28vw !important;
  }

  .vertical-center {
    height: 25vh !important;
  }

  .pt-150 {
    padding-top: 130px !important;
  }

  .map-size {
    width: 100% !important;
    max-width: 1000px;
    height: 250px;
  }
}